import { Login } from "@osjs/client";
import logo from "../logo.svg";
import loading from "../loading.gif";
import axios from "axios";
import * as CryptoJS from "crypto-js";

// For Encryption/Decryption
const encryptDecryptValuePassword = "fHnP9iso5WM68B82LlWMtxT";
const encryptDecryptKeyPassword = "sK0Re5q5GRADKJxWd1yhBgp";
const salt = CryptoJS.enc.Hex.parse("4acfedc7dc72a9003a0dd721d7642bde");
const iv = CryptoJS.enc.Hex.parse("69135769514102d0eded589ff874cacd");

const localIpUrl = require("local-ip-url");

export default class MyCustomLogin extends Login {
  // ensures a value is not empty, null or undefined
  valueIsDefined(value) {
    if (
      value === "" ||
      value === null ||
      value === "null" ||
      value === undefined ||
      value === "undefined"
    ) {
      return false;
    }
    return true;
  }

  // For Encryption/Decryption
  encryptData(data, password) {
    let key128Bits100Iterations = CryptoJS.PBKDF2(password, salt, {
      keySize: 128 / 32,
      iterations: 100,
    });
    let encryptOutput = CryptoJS.AES.encrypt(data, key128Bits100Iterations, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();

    return encryptOutput;
  }

  decryptData(data, password) {
    let key128Bits100Iterations = CryptoJS.PBKDF2(password, salt, {
      keySize: 128 / 32,
      iterations: 100,
    });
    let decryptOutput = CryptoJS.AES.decrypt(data, key128Bits100Iterations, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);

    return decryptOutput;
  }

  encryptToLocalStorage(key, data) {
    localStorage.setItem(
      this.encryptData(key, encryptDecryptKeyPassword),
      this.encryptData(data, encryptDecryptValuePassword)
    );
  }

  decryptFromLocalStorage(key, json = true) {
    let encryptedKey = this.encryptData(key, encryptDecryptKeyPassword);
    if (this.valueIsDefined(localStorage.getItem(encryptedKey))) {
      if (json == true) {
        let result = "";
        try {
          result = JSON.parse(
            this.decryptData(
              localStorage.getItem(encryptedKey),
              encryptDecryptValuePassword
            )
          );
        } catch (err) {
          console.log(err, "json error");
        } finally {
          return result;
        }
      }
      else
        return this.decryptData(
          localStorage.getItem(encryptedKey),
          encryptDecryptValuePassword
        );
    } else {
      return "";
    }
  };

  removeFromLocalStorage(key) {
    let encryptedKey = this.encryptData(key, encryptDecryptKeyPassword);
    localStorage.removeItem(encryptedKey);
  }

  async makeRemoteRequest(
    method,
    params,
  ) {
    let url = "https://api.testing.melone.io";
    let httpOptions = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    method = method.toLowerCase();
    if (method === "get" || method === "delete") {
      if (method === "get") {
        url += `?${params}`;
      } else {
        httpOptions.data = params;
      }
      return await axios[method](url, httpOptions)
        .then((response) => {
          let data = response.data;
          if (data.error === false) {
            return data.result.value;
          } else {
            throw new Error(data.error.msg);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
    } else if (method === "post" || method === "put" || method === "patch") {
      return await axios[method](url, params, httpOptions)
        .then((response) => {
          let data = response.data;
          if (data.error === false) {
            return data.result.value;
          } else {
            throw new Error(data.error.msg);
          }
        })
        .catch((err) => {
          throw new Error(err);
        });
    } else {
      throw new Error(`Method not implemented: ${method}`);
    }
  }

  checkIfUserIsLoggedIn() {
    let user = this.decryptFromLocalStorage("user");
    if (this.valueIsDefined(user)) {
      user.resurrect = true;
      this.emit("login:post", user);
    } else {
      this.checkIfShouldResetPassword();
    }
  }

  checkIfShouldResetPassword() {
    const queryString = window.location.search;
    if (this.valueIsDefined(queryString)) {
      const urlParams = new URLSearchParams(queryString);
      if (this.valueIsDefined(urlParams)) {
        const request = urlParams.get("req");
        if (request === "resetpass") {
          let timer = setTimeout(() => {
            document.querySelector("#loginSection").classList.add("hidden");
            document
              .querySelector("#verifyMailCodeSection")
              .classList.remove("hidden");

            const username = urlParams.get("uname");
            const authCode = urlParams.get("code");
            this.verifyAuthCode(authCode, username);
            clearTimeout(timer);
          }, 0);
        }
      }
    }
  }

  formatErrorMessage(message) {
    let errorMessage = message;
    if (errorMessage) {
      if (typeof errorMessage == "object") {
        errorMessage = errorMessage.toString();
      }
      errorMessage = errorMessage.replaceAll("AxiosError: ", "");
      errorMessage = errorMessage.replaceAll("Error: ", "");
      if (errorMessage.includes("Failed to fetch")) {
        errorMessage =
          "Could not reach server. Please check your connection and try again later.";
      }
    }
    return errorMessage;
  }

  displayErrorMessage(message) {
    document.querySelector("#error-section").innerHTML = `${message}`;
    document.querySelector("#error-section").classList.remove("hidden");
    document.querySelector("#error-section").classList.add("osjs-login-error");
  }

  hideErrorMessage() {
    document.querySelector("#error-section").innerHTML = "";
    document
      .querySelector("#error-section")
      .classList.remove("osjs-login-error");
    document.querySelector("#error-section").classList.add("hidden");
  }

  displayLoadingIndicator() {
    document.querySelector("#loadingImage").classList.remove("hidden");
    document.querySelector("#loadingImage").classList.add("inline");
  }

  hideLoadingIndicator() {
    document.querySelector("#loadingImage").classList.remove("inline");
    document.querySelector("#loadingImage").classList.add("hidden");
  }

  resetLoginForm() {
    document.querySelector("#username").value = "";
    document.querySelector("#password").value = "";
  }

  resetForgotPasswordForm() {
    document.querySelector("#email").value = "";
  }

  resetVerifyAuthForm() {
    document.querySelector("#hiddenEmail").value = "";
    document.querySelector("#authCode").value = "";
    document.querySelector("#userName").value = "";
  }

  resetNewPasswordForm() {
    document.querySelector("#hiddenUsername").value = "";
    document.querySelector("#hiddenAuthCode").value = "";
    document.querySelector("#newPassword").value = "";
    document.querySelector("#passwordConfirmation").value = "";
    document.querySelector("#hint").value = "";
  }

  async requestPasswordReset(email, resendCode = false) {
    const machineID = localIpUrl();

    let method = "post";
    let params = new FormData();

    // These parameters are always passed
    params.append("_req", "reqpwrst");

    // any other parameter is only passed if not empty or null
    if (this.valueIsDefined(email)) {
      params.append("email", email);
    }
    if (this.valueIsDefined(machineID)) {
      params.append("rh", machineID);
    }

    this.displayLoadingIndicator();

    if (!resendCode) {
      document
        .querySelector("#resetPassSubmitBtn")
        .setAttribute("disabled", true);
    }

    try {
      const response = await this.makeRemoteRequest(
        method,
        params,
      );

      if (!resendCode) {
        document.querySelector("#resetPassSection").classList.add("hidden");
        document
          .querySelector("#verifyAuthCodeSection")
          .classList.remove("hidden");
        document.querySelector("#hiddenEmail").setAttribute("value", email);
      }
    } catch (err) {
      this.displayErrorMessage(`${this.formatErrorMessage(err)}`);
    } finally {
      this.hideLoadingIndicator();
      if (!resendCode) {
        document
          .querySelector("#resetPassSubmitBtn")
          .removeAttribute("disabled");
      }
    }
  }

  async verifyAuthCode(authCode, username) {
    let method = "post";
    let params = new FormData();

    // These parameters are always passed
    params.append("_req", "conreset");

    // any other parameter is only passed if not empty or null
    if (this.valueIsDefined(authCode)) {
      params.append("code", authCode);
    }
    if (this.valueIsDefined(username)) {
      params.append("uname", username);
    }

    this.displayLoadingIndicator();
    document
      .querySelector("#verifyCodeSubmitBtn")
      .setAttribute("disabled", true);

    try {
      const response = await this.makeRemoteRequest(
        method,
        params,
      );

      document.querySelector("#verifyAuthCodeSection").classList.add("hidden");
      document.querySelector("#newPasswordSection").classList.remove("hidden");
      document.querySelector("#verifyMailCodeSection").classList.add("hidden");

      document
        .querySelector("#hiddenUsername")
        .setAttribute("value", response[0].USERNAME);
      document
        .querySelector("#hiddenAuthCode")
        .setAttribute("value", response[0].AUTHCODE);
    } catch (err) {
      this.displayErrorMessage(`${this.formatErrorMessage(err)}`);
    } finally {
      this.hideLoadingIndicator();
      document
        .querySelector("#verifyCodeSubmitBtn")
        .removeAttribute("disabled");
    }
  }

  async setNewPassword(username, authCode, newPassword, hint) {
    let method = "post";
    let params = new FormData();

    // These parameters are always passed
    params.append("_req", "restpw");

    // any other parameter is only passed if not empty or null
    if (this.valueIsDefined(username)) {
      params.append("uname", username);
    }
    if (this.valueIsDefined(authCode)) {
      params.append("code", authCode);
    }
    if (this.valueIsDefined(newPassword)) {
      params.append("pass", newPassword);
    }
    if (this.valueIsDefined(hint)) {
      params.append("hint", hint);
    }

    this.displayLoadingIndicator();
    document.querySelector("#newPassSubmitBtn").setAttribute("disabled", true);

    try {
      const response = await this.makeRemoteRequest(
        method,
        params,
      );
      document
        .querySelector("#passResetConfirmation")
        .classList.remove("hidden");
      document.getElementById("backToLoginBtn3").click();
    } catch (err) {
      this.displayErrorMessage(`${this.formatErrorMessage(err)}`);
    } finally {
      this.hideLoadingIndicator();
      document.querySelector("#newPassSubmitBtn").removeAttribute("disabled");
    }
  }

  render() {
    this.checkIfUserIsLoggedIn();
    // Set a custom class name
    this.$container.className = "my-custom-login";
    let date = new Date();

    // Add your HTML content
    this.$container.innerHTML = `
          <div class="loading-container"><img src="${loading}" id="loadingImage" height="24" class="hidden" /></div>
          <div class="osjs-login-logo" data-position="top" style="background-image: url(${logo});"></div>
          <div id="error-section"></div>
          <div id="loginSection">
            <div id="passResetConfirmation" class="hidden">
              <br>
              <div class="align-center">
                <span class="instructions">Password successfully reset! Please login.</span>
              </div>
            </div>
            <form action="" id="loginForm" autocomplete="off">
              <input type="text" placeholder="username" id="username" />
              <input type="password" placeholder="password" id="password" />
              <button type="submit" class="pointer" id="submitButton">Login</button>
            </form>
            <div class="forgot-pass"><span id="forgetPassBtn" class="pointer">Forgot password?</span></div>
          </div>
          <div id="resetPassSection" class="hidden">
            <br>
            <div class="align-center">
              <h2>Forgot Password?</h2>
              <span class="instructions">No worries, we'll send you reset instructions.</span>
              <form action="" id="resetPasswordForm" autocomplete="off">
                <input type="text" placeholder="Enter your email" id="email" />
                <button type="submit" class="pointer" id="resetPassSubmitBtn">Reset password</button>
              </form>
            </div>
            <div class="forgot-pass"><span id="backToLoginBtn" class="pointer"><span class="arrows">&larr;</span> Back to login</span></div>
          </div>
          <div id="verifyAuthCodeSection" class="hidden">
            <br>
            <div class="align-center">
              <h2>Forgot Password?</h2>
              <span class="instructions">Please verify the confirmation code you received.</span>
              <form action="" id="verifyAuthForm" autocomplete="off">
                <input type="text" placeholder="Enter your username" id="userName" />
                <input type="text" placeholder="Enter the confirmation code" id="authCode" />
                <input type="hidden" id="hiddenEmail" />
                <button type="submit" class="pointer" id="verifyCodeSubmitBtn">Verify</button>
              </form>
            </div>
            <div class="forgot-pass"><span id="resendCodeBtn" class="pointer">Didn't receive a code? Click to resend</div>
            <div class="forgot-pass"><span id="backToLoginBtn2" class="pointer"><span class="arrows">&larr;</span> Back to login</span></div>
          </div>
          <div id="newPasswordSection" class="hidden">
            <br>
            <div class="align-center">
              <h2>Forgot Password?</h2>
              <span class="instructions">Please set a new password.</span>
              <form action="" id="newPasswordForm" autocomplete="off">
                <input type="hidden" id="hiddenUsername" />
                <input type="hidden" id="hiddenAuthCode" />
                <input type="password" placeholder="New password" id="newPassword" />
                <input type="password" placeholder="Confirm new password" id="passwordConfirmation" />
                <input type="text" placeholder="Password hint" id="hint" />
                <button type="submit" class="pointer" id="newPassSubmitBtn">Submit</button>
              </form>
            </div>
            <div class="forgot-pass"><span id="backToLoginBtn3" class="pointer"><span class="arrows">&larr;</span> Back to login</span></div>
          </div>
          <div id="verifyMailCodeSection" class="hidden">
            <div class="forgot-pass"><span id="backToLoginBtn4" class="pointer"><span class="arrows">&larr;</span> Back to login</span></div>
          </div>
          <div class="copyright">Copyright @ ${date.getFullYear()} Lemunz.</div> 
    `;

    document.querySelector("#forgetPassBtn").addEventListener("click", () => {
      document.querySelector("#resetPassSection").classList.remove("hidden");
      document.querySelector("#verifyAuthCodeSection").classList.add("hidden");
      document.querySelector("#loginSection").classList.add("hidden");
      this.hideErrorMessage();
      this.resetLoginForm();
      this.resetForgotPasswordForm();
    });

    document.querySelector("#loginForm").addEventListener("submit", (e) => {
      e.preventDefault();
      const username = document.querySelector("#username").value;
      const password = document.querySelector("#password").value;
      if (!username || !password) {
        this.displayErrorMessage("Please provide a username and a password.");
      } else if (!username.includes("@")) {
        this.displayErrorMessage("Invalid username or password");
      } else {
        const magic = "1684708388";
        const realUsername = username.split("@")[0];
        const orgAlias = username.split("@")[1];

        if (!orgAlias) {
          this.displayErrorMessage("Invalid username or password");
        } else {
          this.emit("login:post", {
            username: realUsername,
            orgAlias: orgAlias,
            password: password,
            magic: magic,
          });
        }
      }
    });

    document.querySelector("#backToLoginBtn").addEventListener("click", () => {
      document.querySelector("#loginSection").classList.remove("hidden");
      document.querySelector("#resetPassSection").classList.add("hidden");
      this.hideErrorMessage();
      this.resetLoginForm();
      this.resetForgotPasswordForm();
    });

    document.querySelector("#backToLoginBtn2").addEventListener("click", () => {
      document.querySelector("#loginSection").classList.remove("hidden");
      document.querySelector("#verifyAuthCodeSection").classList.add("hidden");
      this.hideErrorMessage();
      this.resetLoginForm();
      this.resetForgotPasswordForm();
      this.resetVerifyAuthForm();
    });

    document.querySelector("#backToLoginBtn3").addEventListener("click", () => {
      document.querySelector("#loginSection").classList.remove("hidden");
      document.querySelector("#newPasswordSection").classList.add("hidden");
      this.hideErrorMessage();
      this.resetLoginForm();
      this.resetForgotPasswordForm();
      this.resetVerifyAuthForm();
      this.resetNewPasswordForm();
    });

    document.querySelector("#backToLoginBtn4").addEventListener("click", () => {
      document.querySelector("#loginSection").classList.remove("hidden");
      document.querySelector("#verifyMailCodeSection").classList.add("hidden");

      this.hideErrorMessage();
      this.resetLoginForm();
      this.resetForgotPasswordForm();
      this.resetVerifyAuthForm();
      window.location.href = "/";
    });

    document.querySelector("#resendCodeBtn").addEventListener("click", () => {
      const email = document.querySelector("#hiddenEmail").value;
      this.hideErrorMessage();
      this.requestPasswordReset(email, true);
    });

    document
      .querySelector("#resetPasswordForm")
      .addEventListener("submit", (e) => {
        e.preventDefault();
        const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const email = document.querySelector("#email").value;
        if (email) {
          if (emailRegex.test(email)) {
            this.hideErrorMessage();
            this.requestPasswordReset(email);
          } else {
            this.displayErrorMessage("Invalid email");
          }
        } else {
          this.displayErrorMessage("Please enter your email");
        }
      });

    document
      .querySelector("#verifyAuthForm")
      .addEventListener("submit", (e) => {
        e.preventDefault();
        const authCode = document.querySelector("#authCode").value;
        const username = document.querySelector("#userName").value;

        if (authCode && username) {
          this.hideErrorMessage();
          this.verifyAuthCode(authCode, username);
        } else {
          this.displayErrorMessage(
            "Please enter the confirmation code and username"
          );
        }
      });

    document
      .querySelector("#newPasswordForm")
      .addEventListener("submit", (e) => {
        e.preventDefault();
        const newPassword = document.querySelector("#newPassword").value;
        const passwordConfirmation = document.querySelector(
          "#passwordConfirmation"
        ).value;
        const hint = document.querySelector("#hint").value;
        const username = document.querySelector("#hiddenUsername").value;
        const authCode = document.querySelector("#hiddenAuthCode").value;

        if (!newPassword) {
          this.displayErrorMessage("Please enter the new password");
        } else if (!passwordConfirmation) {
          this.displayErrorMessage("Please confirm the new password");
        } else if (newPassword !== passwordConfirmation) {
          this.displayErrorMessage("Passwords don't match");
        } else {
          this.hideErrorMessage();
          this.setNewPassword(username, authCode, newPassword, hint);
        }
      });

    // Bind the events
    // this.on("login:start", () => console.log("Currently trying to log in..."));
    this.on("login:start", () => {
      console.log("Currently trying to log in...");
      document.querySelector("#submitButton").setAttribute("disabled", true);
      this.displayLoadingIndicator();
    });
    this.on("login:stop", () => {
      console.log("Login was aborted or stopped");
      document.querySelector("#submitButton").removeAttribute("disabled");
      this.hideLoadingIndicator();
    });
    this.on("login:error", (message, exception) => {
      this.displayErrorMessage(`${this.formatErrorMessage(exception)}`);
      document.querySelector("#submitButton").removeAttribute("disabled");
      this.hideLoadingIndicator();
    });
  }
}
